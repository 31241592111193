<template>
  <div class="nav-bar">
    <v-app-bar color="#ffffff" :elevation="vDefaultElevation">
      <div class="d-flex align-center">
        <v-app-bar-nav-icon
          @click.stop="handleShowNavDrawer"
          v-if="roles.length > 0"
        ></v-app-bar-nav-icon>
        <img
          class="avatar"
          src="/static/image/logo.png"
          @click="handleToHome"
          v-if="roles.length === 0 || !isMobile"
        />
        <span
          class="logo-txt cursor"
          @click="handleToHome"
          v-if="roles.length === 0 || !isMobile"
        >
          <span class="logo-first-txt">B</span>idex
        </span>
      </div>

      <v-spacer></v-spacer>
      <v-menu :rounded="true" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            :depressed="true"
            v-bind="attrs"
            v-on="on"
            class="btn-language"
          >
            <v-icon size="26"> mdi-earth </v-icon>
            <span class="lang-txt">{{ language }}</span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item class="cursor">
            <v-list-item-title @click="changeLang('en')" class="lang-txt">
              {{ $t("lang.en") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="cursor">
            <v-list-item-title @click="changeLang('jp')" class="lang-txt">
              {{ $t("lang.jp") }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <div
        v-if="walletAddress.length > 0 && !showChangeNetBtn && roles.length > 0"
        class="address text-unselect"
      >
        {{ getAddress(walletAddress) }}
      </div>
      <v-menu :rounded="true" offset-y v-if="roles.length > 0">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon color="#000000" size="26"> mdi-account-circle </v-icon>
            <v-icon color="#000000" size="26"> mdi-chevron-down </v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item class="cursor">
            <v-list-item-title @click="handleLogout" class="lang-txt">{{
              $t("message.logout")
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Login from "@/views/script/login.js";
import Vue from "vue";
import VConsole from "vconsole";
export default {
  name: "NavBar",
  data() {
    return {
      showChangeNetBtn: false,
    };
  },
  mixins: [Login],
  mounted() {
    this.ethereumChange();
  },
  watch: {
    walletAddress: function (val) {
      if (process?.env?.NODE_ENV === "production") {
        this.checkIsShowConsole();
      }
    },
  },
  created() {
    console.log("this.currentNavBarTab", this.currentNavBarTab);
    this.tab = this.currentNavBarTab;
  },
  computed: {
    ...mapState({
      walletAddress: (state) => state.user.walletAddress,
      vDefaultElevation: (state) => state.system.vDefaultElevation,
      language: (state) => state.system.language,
      token: (state) => state.user.token,
      roles: (state) => state.user.roles,
      isShowNavDrawer: (state) => state.system.isShowNavDrawer,
      currentNavBarTab: (state) => state.user.currentNavBarTab,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    getAddress: () => {
      return (val) => {
        let left = val.slice(0, 6);
        let right = val.slice(val.length - 4, val.length);
        return `${left}...${right}`;
      };
    },
  },
  methods: {
    checkIsShowConsole() {
      try {
        let specialWalletAddressArr =
          this.$store.state.user.specialWalletAddressArr;
        let state = false;
        for (let i = 0; i < specialWalletAddressArr.length; i++) {
          let check = new RegExp(specialWalletAddressArr[i], "gi");
          let test = check.test(this.walletAddress);
          if (test) {
            state = true;
            break;
          } else {
            state = false;
          }
        }
        if (state) {
          if (!window.vConsole || !window.vConsole?.isInited) {
            window.vConsole = new VConsole();
            Vue.use(window.vConsole);
            console.log("vConsole", window.vConsole);
          }
        } else {
          try {
            if (window.vConsole && window.vConsole?.isInited) {
              window.vConsole.destroy();
            }
          } catch (e) {
            console.error(e);
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    ethereumChange() {
      window.ethereum.on("chainChanged", (networkIDString) => {
        console.log(
          "chainChanged",
          networkIDString,
          window.switchNetworkNumber
        );
        if (this.roles?.length > 0) {
          this.handleLogout();
        }
      });
      window.ethereum.on("accountsChanged", (accounts) => {
        console.log("accountsChanged", accounts[0]);
        if (this.roles?.length > 0) {
          this.handleLogout();
        }
      });
      // window.provider.on('chainChanged', (chainId) => {
      //   console.log("chainChanged", chainId);
      // })
    },
    changeLang(lang) {
      this.$i18n.locale = lang;
      if (lang !== this.language) {
        this.$store.commit("system/setLanguage", lang);
      }
      // this.$vuetify.lang.current = lang === 'jp' ? 'ja' : lang;
    },
    handleShowNavDrawer() {
      let isShowNavDrawer = this.isShowNavDrawer;
      this.$store.commit("system/isShowNavDrawer", !isShowNavDrawer);
    },
    handleToHome() {
      if (this.$route.path !== "/goods") {
        this.$router.push("/goods");
      }
    },
    handleLogout() {
      console.log("Logout");
      this.$store.dispatch("user/logout").then(() => {
        this.$router.push({ path: "/login" });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.nav-bar {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 10;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.btn-connect {
  padding: 10px 20px;
  border: 1px solid;
  border-radius: 4px;
}

.logo-txt {
  font-weight: bold;
  font-size: 20px;
  color: #f2d857;
}

.logo-first-txt {
  color: #efb82d;
  font-size: 26px;
  margin-left: 4px;
}

.address {
  font-weight: 500;
  color: #ffffff;
  margin: 0 6px;
  background-color: #efb82d;
  border-radius: 4px;
  display: inline-block;
  width: 120px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  font-size: 1.1em;
}

.btn-language {
  padding: unset !important;
}

.avatar {
  width: 40px;
}

.lang-txt {
  font-size: 1.2em;
}
</style>
