export const en = {
  navDrawer: {
    title: "Title",
    becomeAnnualMembershipTitle: "Become an Annual Member",
    becomeAnnualMembershipContent: "Is it sure that it will cost $600.",
    refreshMaxAmountTitle: "Refresh Max Amount",
    refreshMaxAmountContent: "Is it sure that you want to refresh max amount.",
    becameFullMemberTitle: "Became full member",
    becameFullMemberContent: "Is it sure that you want to became full member.",
    payMonthlyFeeTitle: "Pay monthly fee",
    payMonthlyFeeContent: "Is it sure that you want to pay monthly fee.",
    sureShoppingTitle: "Confirm the purchase",
    sureShoppingContent: "Is it sure that you want to buy now.",
    nickName: "Nick Name",
    maximumBalanceTip: "Maximum balance can be withdrawn",
    wallet: "Wallet",
    invitationCode: "Your Invitation Code",
    monthlyFee: "Monthly Fee",
    upgrade: "Upgrade",
    personalCenter: "Personal Center",
    withdrawal: "Withdrawal",
    withdrawalNow: "Withdrawal Now",
    withdrawalAmountTitle: "Withdrawal Amount ($)",
    enterAmountTip: "Please enter the amount",
    becameFullMember: "Became Full Member",
    withdrawTip:
      "Tip: You can withdraw a total of {amount} at your current license, and you must keep your account balance greater than $100",
    successfulWithdrawal: "Successful withdrawal, immediately to the account.",
    withdrawalErrorTip:
      "The withdrawal amount cannot be greater than the wallet balance.",
    rechargeSuccess: "Recharge success",
    transactionFail: "Transaction failure. Please try again later.",
    invitation: "Unilevel",
    placement: "Placement",
    annualMembershipErrorTip: "Not available for purchase.",
  },
  dialog: {
    enterWithdrawalAmountTitle: "Please enter the withdrawal amount",
    disagree: "Disagree",
    agree: "Agree",
  },
  message: {
    logout: "Logout",
    waitForTransaction: "Please wait for the transaction to be confirmed",
    waitForDelivery: "Please wait for the successful delivery",
    enterAmountTip: "Please enter the amount",
    buySuccess: "Purchase success",
    loading: "Waiting for processing",
    insufficientBalance: "Insufficient balance",
    loadingTip1: "Checking the wallet balance, please wait patiently",
    loadingTip2:
      "In the process of initiating wallet transactions, please wait patiently",
    loadingTip3: "wallet transaction underway, please wait patiently",
    waitingTip1:
      "The product information is being confirmed, please wait patiently",
    waitingTip2: "Creating an order for goods, please wait patiently",
    waitingTip3:
      "Creating a wallet merchandise trade order, please wait patiently",
    waitingTip4: "Transferring nft, please wait patiently",
    showText1: "Failed to create an item order. Please try again later",
    showText2: "There is a transaction in progress, please try again later",
    showText3: "The product information is wrong, please refresh and try again",
    showText4:
      "Failed to create an order for goods. Please check the network and try again later",
  },
  login: {
    connectWallet: "Connect Wallet",
    sloganTitle: "Web 3.0 Era \n One-stop Platform",
    reconnect: "Please log in to metamask and reconnect",
    loginBeforeTip: "Please connect your wallet before logging in.",
  },
  personal: {
    noMoreData: "No more data",
  },
  goods: {
    buyNowBtn: "Buy Now",
    invitationCodePrompt: "Please enter the invitation code",
    invitationCode: "Invitation Code",
    discountCode: "Discount Code",
  },
  network: {
    switchNetwork: "Please switch to the {network} network",
    networkError: "Network error",
    tryAgain: "Please try again later",
    refreshAgain: "Please refresh and try again",
    checkLater: "Network error, please check later.",
    checkConnection: "Please check your network connection",
  },
  lang: {
    en: "English",
    jp: "Japanese",
  },
};
