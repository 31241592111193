import router from "@/router/index";
// import store from "@/store/index";
// import { getToken } from "@/utils/auth.js";
window.isFirstLogin = false;
console.log("isFirstLogin", window.isFirstLogin);

let whiteList = ["/login"];
router.beforeEach(async (to, from, next) => {
  // console.log("TO", to, to.path, "FROM", from, from.path);
  if (whiteList.indexOf(to.path) !== -1) {
    next();
  } else {
    next("/login");
  }
  // if (getToken()) {
  //   // if (whiteList.indexOf(to.path) !== -1) {
  //   //   next();
  //   // } else {
  //   let role = store.getters.roles;
  //   if (!role || role.length === 0) {
  //     store
  //       .dispatch("user/checkPermissions")
  //       .then((res) => {
  //         next(res);
  //       })
  //       .catch((err) => {
  //         next("/login");
  //       });
  //   } else {
  //     console.log("has role");
  //     next();
  //   }
  //   // }
  // } else {
  //   if (whiteList.indexOf(to.path) !== -1) {
  //     next();
  //   } else {
  //     next("/login");
  //   }
  // }
});

router.afterEach((to, from, next) => {
  window.gtag("config", "G-7PEB494JJ9", {
    page_title: to.name,
    page_path: to.path,
    page_location: window.location.href,
  });
});
